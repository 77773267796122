import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  constructor() {
  }

  // Generic multi-column multi-input text filter (google style)
  // usage: filterBy("filterText", ["productNo", "name", "description"])
  public filterBy(list: object[], filter: string, by: string[]) {
    const subfilters = filter.split(' ');
    return list.filter(product => {
      return this.match(product, subfilters, by);
    });
  }

  // returns true if all filters are contained in at least one attribute
  match(subject: object, filters: string[], by: string[]) {
    const mismatch = filters.find((filter) => {
      const subMatch = by.find((attribute) => {
        return (subject[attribute] || '').toString().toUpperCase().includes(filter.toUpperCase());
      });
      return subMatch === undefined;
    });
    return mismatch === undefined;
  }

}
