import {Injectable} from '@angular/core';
import {forEach} from 'lodash';
import {NgForm} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormService {
  constructor() {
  }

  public markFormTouched(form: NgForm) {
    forEach(form.controls, c => this.markControlTouched(c));
  }

  public markControlTouched(c: any) {
    c.touched = true;
  }

}
