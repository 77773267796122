import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Customer} from '../models/customer';
import { HttpClient } from '@angular/common/http';
import {Endpoints} from './endpoints';
import {CustomerAbstract} from '../models/customer-abstract';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(private httpClient: HttpClient) {
  }

  findAll(): Observable<CustomerAbstract[]> {
    const url = Endpoints.baseUrl + Endpoints.customers;
    return this.httpClient.get<CustomerAbstract[]>(url);
  }

  createOrUpdate(customer: Customer): Observable<Customer> {
    if (customer.id) {
      const url = Endpoints.baseUrl + Endpoints.customers + '/' + customer.id;
      return this.httpClient.put<Customer>(url, customer);
    } else {
      const url = Endpoints.baseUrl + Endpoints.customers;
      return this.httpClient.post<Customer>(url, customer);
    }
  }

  findById(id: number): Observable<Customer> {
    const url = Endpoints.baseUrl + Endpoints.customers + '/' + id;
    return this.httpClient.get<Customer>(url);
  }

  findByCustomerNumber(customerNumber: number): Observable<Customer> {
    const url = Endpoints.baseUrl + Endpoints.customers + '/' + customerNumber;
    return this.httpClient.get<Customer>(url);
  }

  archive(id: number): Observable<any> {
    const url = `${Endpoints.baseUrl + Endpoints.customers}/${id}/archive`;
    return this.httpClient.put(url, undefined);
  }

  reactivate(id: number) {
    const url = `${Endpoints.baseUrl + Endpoints.customers}/${id}/activate`;
    return this.httpClient.put(url, undefined);
  }
}
