<form #form="ngForm" (submit)="submitSingleResult()">
  <div class="modal-header">
    <h4 class="modal-title">Produkt auswählen</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group mb-3">
      <label class="form-label">Suchen</label>
      <input id="filterTerm" class="form-control" name="filterTerm" [(ngModel)]="filterTerm" (ngModelChange)="filterChanged()" ngbAutofocus>
    </div>

    <div class="list list-row list-hoverable">
      <div class="list-item" *ngFor="let product of filteredList">
        <div>{{product.productNo}}</div>
        <div>{{product.name}}</div>
        <div class="list-item-actions show">
          <button type="button" class="btn btn-outline-primary btn-sm" (click)="submit(product)">Auswählen</button>
        </div>
      </div>
    </div>

  </div>
</form>
