import {Component, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {FormService} from '../../../core/services/form.service';
import {MasterdataService} from '../../../core/services/masterdata.service';
import {MasterData} from '../../../core/models/masterdata';
import {InvoiceItem} from '../../../core/models/invoiceitem';
import {Product} from '../../../core/models/product';
import {ProductService} from '../../../core/services/product.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-item-edit',
  templateUrl: './item-edit.component.html',
  styleUrls: ['./item-edit.component.scss']
})
export class ItemEditComponent implements OnInit {

  @ViewChild('itemForm') itemForm: NgForm;

  item: InvoiceItem = {};
  masterData: MasterData[];
  vatPositionList: MasterData[];
  paymentTypeList: MasterData[];
  unityoptionList: MasterData[];
  bookholdNoList: MasterData[];
  productList: Product[];

  constructor(private masterdataService: MasterdataService,
              private notificationService: ToastrService,
              private formService: FormService,
              private productService: ProductService,
              public activeModal: NgbActiveModal) {
  }

  ngOnInit() {
    this.refresh();
  }

  refresh() {
    this.masterdataService.getAll().subscribe(
      next => this.masterData = next,
      error =>
        this.notificationService.error('Fehler beim Laden der Masterdaten', 'Error'),
      () => {
        this.vatPositionList = this.masterdataService.getVatPositions(this.masterData);
        if (this.item.vatPosition !== undefined) {
          this.item.vatPosition = this.getById(this.vatPositionList, this.item.vatPosition.id);
        }
        this.unityoptionList = this.masterdataService.getUnityOptions(this.masterData);
        this.bookholdNoList = this.masterdataService.getBookholdPositions(this.masterData);
        this.paymentTypeList = this.masterdataService.getPaymentTypes(this.masterData);
      }
    );

    this.productService.findAll().subscribe(
      next => this.productList = next,
      error =>
        this.notificationService.error('Fehler beim Laden der Produkte', 'Error'),
      () => {
        if (this.item.product) {
          this.item.product = this.getById(this.productList, this.item.product.id);
        }
      }
    );
  }

  saveItem(item: InvoiceItem) {
    if (this.itemForm.valid === false) {
      this.notificationService.warning('Please check the form', 'Data invalid');
      this.formService.markFormTouched(this.itemForm);
      return;
    }
    if (this.item.serialNumber === undefined) {
      this.item.serialNumber = '';
    }

    if (this.item.description === undefined) {
      this.item.description = '';
    }

    console.log(this.item);

    this.activeModal.close(this.item);
    this.item = {};
  }

  getById(list, id): MasterData {
    return list.find(element => element.id === id);
  }

}
