import {Component, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {FormService} from '../../../core/services/form.service';
import {MasterdataService} from '../../../core/services/masterdata.service';
import {MasterData} from '../../../core/models/masterdata';
import {InvoiceItem} from '../../../core/models/invoiceitem';
import {Product} from '../../../core/models/product';
import {ProductService} from '../../../core/services/product.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';
import {RecurringItem} from '../../../core/models/recurring-item';

@Component({
  selector: 'app-recurring-item-edit',
  templateUrl: './recurring-item-edit.component.html',
  styleUrls: ['./recurring-item-edit.component.scss']
})
export class RecurringItemEditComponent implements OnInit {

  @ViewChild('itemForm') itemForm: NgForm;

  item: RecurringItem = {
    quantity: 1,
    unity: 'Stk',
  } as RecurringItem;
  masterData: MasterData[];
  vatPositionList: MasterData[];
  paymentTypeList: MasterData[];
  unityoptionList: MasterData[];
  bookholdNoList: MasterData[];
  repeatTypeList: MasterData[];
  productList: Product[];

  showPlaceholders = false;
  availablePlaceholders = [
    {key: 'zaehler', value: 'Fortlaufende Nummer mit der Anzahl der bisherigen Verrechnungen'},
    {key: 'datum', value: 'Aktuelles Datum im Format \'31.12.2007\''},
    {key: 'datumlang', value: 'Aktuelles Datum im Format \'31. Dezember 2007\''},
    {key: 'intervalstart', value: 'Anfangsdatum des Abrechnungszeitraums im Format \'31.12.2007\''},
    {key: 'intervalstartlang', value: 'Anfangsdatum des Abrechnungszeitraums im Format \'31. Dezember 2007\''},
    {key: 'intervalende', value: 'Enddatum des Abrechnungszeitraums im Format \'31.12.2007\''},
    {key: 'intervalendelang', value: 'Enddatum des Abrechnungszeitraums im Format \'31. Dezember 2007\''},
    {key: 'seit', value: 'Startdatum der ersten Verrechnung der Position im Format \'31.12.2007\''},
    {key: 'seitlang', value: 'Startdatum der ersten Verrechnung der Position im Format \'31. Dezember 2007\''},
  ];

  constructor(
    private masterdataService: MasterdataService,
    private notificationService: ToastrService,
    private formService: FormService,
    private productService: ProductService,
    public activeModal: NgbActiveModal,
  ) {
  }

  ngOnInit() {
    this.refresh();
  }

  refresh() {
    this.masterdataService.getAll().subscribe(
      next => this.masterData = next,
      error =>
        this.notificationService.error('Fehler beim Laden der Masterdaten', 'Error'),
      () => {
        this.vatPositionList = this.masterdataService.getVatPositions(this.masterData);
        if (this.item.vatPosition !== undefined) {
          this.item.vatPosition = this.getById(this.vatPositionList, this.item.vatPosition.id);
        }
        this.unityoptionList = this.masterdataService.getUnityOptions(this.masterData);
        this.bookholdNoList = this.masterdataService.getBookholdPositions(this.masterData);
        this.paymentTypeList = this.masterdataService.getPaymentTypes(this.masterData);
        this.repeatTypeList = this.masterdataService.getRepeattypes(this.masterData);
      }
    );

    this.productService.findAll().subscribe(
      next => this.productList = next,
      error =>
        this.notificationService.error('Fehler beim Laden der Produkte', 'Error'),
      () => {
      }
    );
  }

  saveItem(item: InvoiceItem) {
    if (this.itemForm.valid === false) {
      this.notificationService.warning('Please check the form', 'Data invalid');
      this.formService.markFormTouched(this.itemForm);
      return;
    }

    if (this.item.description === undefined) {
      this.item.description = '';
    }

    console.log(this.item);

    this.activeModal.close(this.item);
    this.item = {} as RecurringItem;
  }

  getById(list, id): MasterData {
    return list.find(element => element.id === id);
  }

}
