<div class="modal-header">Wiederkehrenden Posten anlegen/bearbeiten
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form #itemForm="ngForm">
    <div class="row">
      <div class="col-2">
        <div class="form-group">
          <label for="quantity">Anzahl</label>
          <input id="quantity" type="number" name="quantity" class="form-control" [(ngModel)]="item.quantity" required appAutoFocus/>
        </div>
        <div class="form-group">
          <label for="unity">Einheit</label>
          <select id="unity" name="unity" class="form-control" [(ngModel)]="item.unity" required>
            <option *ngFor="let unity of unityoptionList" [ngValue]="unity.value">{{unity.label}}</option>
          </select>
        </div>
        <div class="form-group">
          <label for="bookholdNo">Buchhaltungsnr.</label>
          <select id="bookholdNo" name="bookholdNo" class="form-control" [(ngModel)]="item.bookholdNo" required>
            <option *ngFor="let bookholdNo of bookholdNoList" [ngValue]="bookholdNo.value">{{bookholdNo.label}}</option>
          </select>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label for="name">Name</label>
          <input id="name" type="string" name="name" class="form-control" [(ngModel)]="item.name" required/>
        </div>
        <div class="form-group">
          <label for="description">Beschreibung</label>
          <textarea id="description" type="string" rows="5" name="description" class="form-control"
                    [(ngModel)]="item.description"></textarea>
        </div>
      </div>
      <div class="col-4">
        <div class="row">
          <div class="form-group col-6 mb-3">
            <label for="netPrice">Preis(Netto)</label>
            <input id="netPrice" type="number" name="netPrice" class="form-control" [(ngModel)]="item.netPrice" required/>
          </div>
          <div class="form-group col-6 mb-3">
            <label for="vatPosition">Steuerposition</label>
            <select id="vatPosition" name="vatPosition" class="form-control" [(ngModel)]="item.vatPosition" required>
              <option *ngFor="let vatPos of vatPositionList" [ngValue]="vatPos">{{vatPos.label}}</option>
            </select>
          </div>
          <div class="form-group col-12">
            <label for="repeatType">Wiederholung</label>
            <select id="repeatType" name="repeatType" class="form-control" [(ngModel)]="item.repeatType" required="true">
              <option *ngFor="let type of repeatTypeList" [ngValue]="type.code">{{type.label}}</option>
            </select>
          </div>
          <div class="form-group col-6 mb-3">
            <label for="startDate">Beginn</label>
            <input id="startDate" type="date" class="form-control" name="startDate" [(ngModel)]="item.startDate" required/>
          </div>
          <div class="form-group col-6 mb-3">
            <label for="endDate">Ende</label>
            <input id="endDate" type="date" class="form-control" name="endDate" [(ngModel)]="item.endDate"/>
          </div>
          <div class="form-group col-6 mb-3">
            <label for="lastDueDate">Letzte Verrechnung</label>
            <input id="lastDueDate" type="date" class="form-control" name="lastDueDate" [(ngModel)]="item.lastDueDate"
                   readonly="true" />
          </div>
          <div class="form-group col-6 mb-3">
            <label for="nextDueDate">Nächste Verrechnung</label>
            <input id="nextDueDate" type="date" class="form-control" name="nextDueDate" [(ngModel)]="item.nextDueDate"
                   [readOnly]="!item.id" />
          </div>
        </div>
      </div>
    </div>
  </form>

  <div>
    <h3>Verfügbare Platzhalter</h3>
    <table class="table" *ngIf="showPlaceholders">
      <tr *ngFor="let placeholder of availablePlaceholders">
        <td>
          {{placeholder.key}}
        </td>
        <td>
          {{placeholder.value}}
        </td>
      </tr>
    </table>
    <button class="btn btn-sm btn-secondary" (click)="showPlaceholders = true" *ngIf="!showPlaceholders">Einblenden</button>
    <button class="btn btn-sm btn-secondary" (click)="showPlaceholders = false" *ngIf="showPlaceholders">Ausblenden</button>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-warning me-auto" (click)="activeModal.dismiss('Close click')">
    Abbrechen
  </button>
  <button type="button" class="btn btn-primary" (click)="saveItem(item)">Speichern</button>
</div>
