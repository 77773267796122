<div class="modal-header">
  <h4 class="modal-title">{{ title }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('close')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p *ngIf="message" style="white-space: pre-line;">{{ message }}</p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-primary" (click)="activeModal.close('ok')">OK</button>
  <button type="button" class="btn btn-outline-secondary" (click)="activeModal.dismiss('abort')">Abbrechen</button>
</div>
