import {Component, OnInit} from '@angular/core';
import {Customer} from '../../../core/models/customer';
import {CustomerService} from '../../../core/services/customer.service';
import {FilterService} from '../../filter.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-select-customer-modal',
  templateUrl: './customer-select-modal.component.html',
})
export class CustomerSelectModalComponent implements OnInit {

  customerList: Customer[] = [];

  filterTerm = '';
  filteredCustomerList: Customer[] = [];

  constructor(
    public activeModal: NgbActiveModal,
    private customerService: CustomerService,
    private filterService: FilterService,
  ) {
  }

  ngOnInit(): void {
    this.customerService.findAll().subscribe(
      result => {
        this.customerList = result;
        this.filterChanged();
      }
    );
  }

  filterChanged(): void {
    const matchedEntries = this.filterService
      .filterBy(this.customerList, this.filterTerm, ['customerNo', 'name']);

    this.filteredCustomerList = matchedEntries.slice(0, 10);
  }

  submit(customer: Customer) {
    this.activeModal.close(customer);
  }

  submitSingleResult() {
    if (this.filteredCustomerList.length === 1) {
      this.activeModal.close(this.filteredCustomerList[0]);
    }
  }
}
