import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {Endpoints} from './endpoints';
import {Product} from '../models/product';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private httpClient: HttpClient) {
  }

  findAll(): Observable<Product[]> {
    const url = Endpoints.baseUrl + Endpoints.products;

    return this.httpClient.get<Product[]>(url);
  }

  createOrUpdate(product: Product): Observable<Product> {
    if (product.id) {
      const url = Endpoints.baseUrl + Endpoints.products + '/' + product.id;
      return this.httpClient.put(url, product);
    } else {
      const url = Endpoints.baseUrl + Endpoints.products;
      return this.httpClient.post(url, product);
    }
  }

  delete(product: Product): Observable<any> {
    const url = Endpoints.baseUrl + Endpoints.products + '/' + product.id;
    return this.httpClient.delete(url);
  }

  findById(id: number): Observable<Product> {
    const url = Endpoints.baseUrl + Endpoints.products + '/' + id;
    return this.httpClient.get<Product>(url);
  }
}
