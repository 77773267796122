import {Component, OnInit} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-pdf-view-modal',
  templateUrl: './pdf-view-modal.component.html',
  styleUrls: ['./pdf-view-modal.component.scss']
})
export class PdfViewModalComponent implements OnInit {

  dataBase64: Blob;

  constructor(
    public activeModal: NgbActiveModal,
    private domSanitizer: DomSanitizer,
  ) {
  }

  ngOnInit(): void {
  }

  load(data: Blob) {
    this.dataBase64 = data;
  }

}
