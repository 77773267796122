<div class="modal-header">Rechnungsposten anlegen/bearbeiten
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form #itemForm="ngForm">
    <div class="row">
      <div class="col-2">
        <div class="form-group">
          <label for="quantity">Anzahl</label>
          <input id="quantity" type="number" name="quantity" class="form-control" [(ngModel)]="item.quantity" required appAutoFocus/>
        </div>
        <div class="form-group">
          <label for="unity">Einheit</label>
          <select id="unity" name="unity" class="form-control" [(ngModel)]="item.unity" required>
            <option *ngFor="let unity of unityoptionList" [ngValue]="unity.value">{{unity.label}}</option>
          </select>
        </div>
      </div>
      <div class="col-7">
        <div class="form-group">
          <label for="name">Name</label>
          <input id="name" type="string" name="name" class="form-control" [(ngModel)]="item.name" required/>
        </div>
        <div class="form-group">
          <label for="description">Beschreibung</label>
          <textarea id="description" type="string" rows="5" name="description" class="form-control"
                    [(ngModel)]="item.description"></textarea>
        </div>
      </div>
      <div class="col-3">
        <div class="form-group">
          <label for="netPrice">Preis(Netto)</label>
          <input id="netPrice" type="number" name="netPrice" class="form-control" [(ngModel)]="item.netPrice" required/>
        </div>
        <div class="form-group">
          <label for="vatPosition">Steuerposition</label>
          <select id="vatPosition" name="vatPosition" class="form-control" [(ngModel)]="item.vatPosition" required>
            <option *ngFor="let vatPos of vatPositionList" [ngValue]="vatPos">{{vatPos.label}}</option>
          </select>
        </div>
        <div class="form-group">
          <label for="bookholdNo">Buchhaltungsnr.</label>
          <select id="bookholdNo" name="bookholdNo" class="form-control" [(ngModel)]="item.bookholdNo" required>
            <option *ngFor="let bookholdNo of bookholdNoList" [ngValue]="bookholdNo.value">{{bookholdNo.label}}</option>
          </select>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-warning me-auto" (click)="activeModal.dismiss('Close click')">
    Abbrechen
  </button>
  <button type="button" class="btn btn-primary" (click)="saveItem(item)">Speichern</button>
</div>
