import {Component, EventEmitter, HostListener, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit {

  @Output()
  files = new EventEmitter<FileList>();
  dragOver = false;

  @HostListener('dragover', ['$event']) onDragOver(evt) {
    this.dragOver = true;
  }

  @HostListener('dragleave', ['$event'])
  public onDragLeave(evt) {
    this.dragOver = false;
  }

  @HostListener('drop', ['$event'])
  public ondrop(evt) {
    this.dragOver = false;
  }

  constructor() {
  }

  ngOnInit(): void {
  }

  handleFileInput(files: FileList) {
    this.files.emit(files);
  }
}
