import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ConfirmDialogComponent} from './compoents/confirm-dialog/confirm-dialog.component';
import {ItemEditComponent} from './compoents/item-edit/item-edit.component';
import {NgbModalModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule} from '@angular/forms';
import {CustomerSelectModalComponent} from './compoents/customer-select-modal/customer-select-modal.component';
import {PdfViewModalComponent} from './compoents/pdf-view-modal/pdf-view-modal.component';
import {ProductSelectModalComponent} from './compoents/product-select-modal/product-select-modal.component';
import {UploadComponent} from './compoents/upload/upload.component';
import {AutoFocusDirective} from './directives/auto-focus.directive';
import {RecurringItemEditComponent} from './compoents/recurring-item-edit/recurring-item-edit.component';
import {DateInputModalComponent} from './compoents/date-input-modal/date-input-modal.component';
import { MarkdownPipe } from './pipes/markdown.pipe';
import {NgxExtendedPdfViewerModule} from "ngx-extended-pdf-viewer";

@NgModule({
    imports: [
        CommonModule,
        NgbModalModule,
        FormsModule,
        NgxExtendedPdfViewerModule,
    ],
    declarations: [
        ConfirmDialogComponent,
        DateInputModalComponent,
        ItemEditComponent,
        CustomerSelectModalComponent,
        PdfViewModalComponent,
        ProductSelectModalComponent,
        UploadComponent,
        AutoFocusDirective,
        RecurringItemEditComponent,
        MarkdownPipe,
    ],
    exports: [
        UploadComponent,
        AutoFocusDirective,
        MarkdownPipe
    ]
})
export class SharedModule {
}
