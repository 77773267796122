import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FilterService} from '../../filter.service';
import {Product} from '../../../core/models/product';
import {ProductService} from '../../../core/services/product.service';
import {filter} from 'lodash';

@Component({
  selector: 'app-product-select-modal',
  templateUrl: './product-select-modal.component.html',
  styleUrls: ['./product-select-modal.component.scss']
})
export class ProductSelectModalComponent implements OnInit {

  productList: Product[] = [];

  filterTerm = '';
  filteredList: Product[] = [];

  constructor(
    public activeModal: NgbActiveModal,
    private productService: ProductService,
    private filterService: FilterService,
  ) {
  }

  ngOnInit(): void {
    this.productService.findAll().subscribe(
      result => {
        this.productList = filter(result, (p: Product) => p.active === true);
        this.filterChanged();
      }
    );
  }

  filterChanged(): void {
    console.log('filter changed');
    const matchedEntries = this.filterService
      .filterBy(this.productList, this.filterTerm, ['name', 'comment', 'description'])
      .slice(0, 50);

    this.filteredList = matchedEntries;
  }

  submit(product: Product) {
    this.activeModal.close(product);
  }

  submitSingleResult() {
    if (this.filteredList.length === 1) {
      this.activeModal.close(this.filteredList[0]);
    }
  }
}
